.modal-contact-form {
  margin-top: 37px;
}

.modal-contact-form__form {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.modal-contact-form__input {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.modal-contact-form__agreement {
  margin-top: 18px;
}

.modal-contact-form__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 52px;
}
