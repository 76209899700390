.footnote-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footnote-list__item {
  line-height: percentage(20 / 16);

  &:not(&:last-child) {
    margin-bottom: 14px;
  }

  &:before {
    content: "* ";
  }

  @include rvp-768 {
    &:not(:last-child) {
      &:not(&:last-child) {
        margin-bottom: 23px;
      }
    }
  }
}