.complimentary-product__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @include vp-1023 {
    display: block;
  }
}

.complimentary-product__product {
  @include vp-1023 {
    margin-bottom: 48px;
  }
}

.complimentary-product__content {
  max-width: 510px;
  padding: 100px 40px 50px 0;

  @include vp-1023 {
    max-width: 600px;
    margin: 0 auto;
    padding: 0;
  }
}

.complimentary-product__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);
  }
}

.complimentary-product__subtitle {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.complimentary-product__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.complimentary-product__product {

  display: flex;
  justify-content: center;
  align-items: center;
}

.complimentary-product__product-img {
  max-width: 100%;
  height: auto;
}

.complimentary-product__action-btns {
  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

