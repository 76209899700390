.document-link {
  display: flex;
}

.document-link__icon {
  flex-shrink: 0;

  width: 32px;
  height: 32px;
  margin-right: 16px;

  color: $color-governor-bay;
}

.document-link__link {
  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.document-link__paragraph {
  margin: 0;

  color: $color-comet;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
