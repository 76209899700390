.custom-select {
  position: relative;

  max-width: 420px;
  width: 100%;
  padding: 0;

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 30px));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: translateY(-9px) rotate(270deg);
    }

    // .custom-select__label {
    //   opacity: 0;
    // }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 1;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: 1px solid red;

      &:focus,
      &:hover {
        border: 1px solid rgba(red, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 1px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 1px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }

  &--inline {
    @include rvp-600 {
      display: flex;
      align-items: center;
    }
  }
}

.custom-select__label {
  position: absolute;
  top: 10px;
  left: 16px;
  transform: scale(percentage(2 / 3));
  transform-origin: top left;

  color: $color-spun-pearl;

  transition: transform $trans-default, opacity $trans-default;

  pointer-events: none;

  opacity: 0;

  .custom-select--inline & {
    display: block;
    position: static;
    transform: none;
    margin-bottom: 12px;

    line-height: percentage(22 / 18);

    @include rvp-600 {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
}

.custom-select__field {
  position: relative;
  flex-grow: 1;
}

.custom-select__placeholder {
  position: absolute;
  top: 18px;
  left: 16px;

  transition: transform $trans-default, opacity $trans-default;

  color: $color-spun-pearl;

  pointer-events: none;
}

.custom-select__button {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 56px;
  padding: 22px 15px 6px;

  font-size: 18px;
  line-height: percentage(26 / 18);

  background-color: $color-transparent;
  border: 1px solid $color-quartz-2;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  transition: border $trans-default;

  &:hover,
  &:focus {
    border-color: $color-matterhorn;
  }

  .custom-select--inline & {
    padding-top: 14px;
    padding-right: 8px;
    padding-bottom: 14px;
  }
}

.custom-select__list {
  position: absolute;
  bottom: -2px;
  z-index: 1;

  width: 100%;
  max-height: 228px;
  margin: 0;
  padding: 8px 0;
  overflow-y: auto;

  list-style: none;

  background-color: $color-default-white;
  border: 1px solid $color-quartz-2;
  border-radius: 8px;

  transform: translateY(100%);
  visibility: hidden;
  opacity: 0;

  transition: visibility $trans-default, opacity $trans-default;
}

.custom-select__item {
  padding: 10px 15px 6px;

  outline: none;
  cursor: pointer;

  transition: background-color $trans-default;

  &[aria-selected="true"] {
    background-color: rgba($color-vis-vis, 0.2);

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: rgba($color-echo-blue, 0.2);
      }
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: rgba($color-echo-blue, 0.2);
    }
  }
}

.custom-select__error {
  position: absolute;
  right: 10px;
  bottom: 2px;

  font-size: 14px;
  line-height: percentage(20 / 14);

  color: red;

  opacity: 0;

  transition: opacity $trans-default;

  pointer-events: none;
}

.custom-select__text {
  flex-shrink: 0;
  width: calc(100% - 44px);
  overflow: hidden;

  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;

  opacity: 0;

  transition: opacity $trans-default 0.1s;

  br {
    display: none;
  }

  .custom-select--inline & {
    flex: 1;
    width: auto;
  }
}

.custom-select__icon {
  transform: translateY(-9px) rotate(90deg);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: 20px;

  color: $color-spun-pearl;

  transition: transform $trans-default;

  .custom-select--inline & {
    transform: rotate(90deg);
    margin-left: 0;
  }

  .custom-select--inline.is-open & {
    transform: rotate(270deg);
  }
}
