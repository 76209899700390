.contacts__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}

.contacts__wrapper {
  display: grid;
  grid-template-columns: 5fr 7fr;
  gap: 30px;

  @include vp-1023 {
    grid-template-columns: 1fr 2fr;
  }

  @include vp-767 {
    display: block;
  }
}

.contacts__content {
  max-width: 370px;

  font-style: normal;

  @include vp-767 {
    margin-bottom: 48px;
  }
}

.contacts__list {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.contacts__item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.contacts__type {
  margin-bottom: 8px;

  font-weight: 400;
}

.contacts__data {
  margin: 0;

  color: $color-comet
}

.contacts__photo {
  width: 100%;
}

.contacts__photo-img {
  width: 100%;
  height: auto;
}
