.tooltip-btn {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  padding: 8px;

  text-decoration: none;
  color: $color-jaguar;

  font-weight: 400;
  font-size: 20px;
  line-height: percentage(28 / 20);

  background-color: $color-moon-yellow;
  border: 1px solid $color-moon-yellow;
  border-radius: 50%;
  cursor: pointer;

  transition:
    color $trans-default,
    background-color $trans-default,
    border-color $trans-default;

  &:disabled {
    color: $color-spun-pearl;
    background-color: $color-quartz;
    border-color: $color-quartz;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      background-color: $color-dark-tangerine;
      border-color: $color-dark-tangerine;
    }
  }

  @include vp-413 {
    width: 66px;
    height: 66px;
  }
}

.tooltip-btn__icon {
  width: 40px;
  height: 40px;

  @include vp-413 {
    width: 30px;
    height: 30px;
  }
}
