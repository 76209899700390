.contacts-form {
  margin: 0;
  padding-top: 93px;
  padding-bottom: 71px;

  text-align: center;

  @include vp-599 {
    padding-top: 60px;
  }
}

.contacts-form__header {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;

  &:not(:last-child) {
    margin-bottom: 44px;
  }

  @include vp-599 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.contacts-form__title {
  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

.contacts-form__description {
  margin: 0;
}

.contacts-form__form {
  max-width: 434px;
  margin-left: auto;
  margin-right: auto;

  &:not(:first-child) {
    margin-top: 44px;
  }

  @include vp-767 {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}

.contacts-form__input {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.contacts-form__agreement {
  text-align: left;
}

.contacts-form__submit {
  &:not(:first-child) {
    margin-top: 25px;
  }
}