.software__wrapper {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 30px;

  @include vp-1239 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-1023 {
    display: block;
  }
}

.software__cover {
  padding-right: 40px;

  @include vp-1239 {
    padding-right: 0;
  }

  @include vp-1023 {
    max-width: 770px;
    margin: 0 auto 70px;
  }
}

.software__cover-img {
  width: 100%;
  height: auto;
}

.software__content {
  @include vp-1239 {
    padding-left: 20px;
  }

  @include vp-1023 {
    max-width: 770px;
    margin: 0 auto;
    padding: 0;
  }
}

.software__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.software__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.software__item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
