.knowledge-base-sections {
  margin-top: 55px;

  @include vp-1023 {
    margin-top: 40px;
  }
}

.knowledge-base-sections__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  margin: 0;
  padding: 0;

  list-style: none;

  @include vp-1023 {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }

  @include vp-767 {
    gap: 20px;
  }

  @include vp-599 {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }

  @include vp-413 {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}

.knowledge-base-sections__link {
  width: 100%;
  height: 100%;
  padding: 32px;

  text-decoration: none;
  color: $color-jaguar;

  border: 1px solid $color-quartz-2;
  border-radius: 4px;
  outline: 4px solid $color-transparent;
  outline-offset: -4px;

  transition:
    color $trans-default,
    outline-color $trans-default;

  &:visited {
    color: $color-jaguar;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      color: $color-governor-bay;

      outline-color: $color-governor-bay;
    }
  }

  @include vp-599 {
    padding: 20px;
  }

  @include vp-413 {
    display: flex;
    align-items: center;
    padding: 16px;
  }
}

.knowledge-base-sections__cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 124px;
  margin: 0 auto;

  color: $color-wild-blue-yonder;

  transition: color $trans-default;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    .knowledge-base-sections__link:hover &,
    .knowledge-base-sections__link:focus &,
    .knowledge-base-sections__link:active & {
      color: $color-governor-bay;
    }
  }

  @include vp-599 {
    width: 88px;
    height: 88px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @include vp-413 {
    flex-shrink: 0;
    align-self: flex-start;
    width: 72px;
    height: 56px;

    &:not(:last-child) {
      margin: 0;
      margin-right: 16px;
    }
  }
}

.knowledge-base-sections__icon {
  width: 88px;
  height: 88px;

  @include vp-599 {
    width: 64px;
    height: 64px;
  }

  @include vp-413 {
    width: 56px;
    height: 56px;
  }
}

.knowledge-base-sections__title {
  margin: 0;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-599 {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @include vp-413 {
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
