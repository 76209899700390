.slider-tabs {
  position: relative;
}

.slider-tabs__container {
  padding: 0 var(--horizontal-padding);

  .slider-tabs--modal & {
    .specifications--modal & {
      padding: 0 48px;
    }

    @include vp-767 {
      .specifications--modal & {
        padding: 0 32px;
      }
    }

    @include vp-413 {
      .specifications--modal & {
        padding: 0 24px;
      }
    }
  }
}

.slider-tabs__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.slider-tabs__item {
  width: max-content;
}
