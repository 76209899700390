.feature-card {
  @include vp-767 {
    display: flex;
  }
}

.feature-card__icon {
  flex-shrink: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 32px;

  color:  $color-governor-bay;

  .feature-card--bright & {
    color: $color-moon-yellow;
  }

  @include vp-767 {
    width: 56px;
    height: 56px;
    margin: 0;
    margin-right: 40px;
  }

  @include vp-413 {
    width: 48px;
    height: 48px;
    margin: 0;
    margin-right: 24px;
  }
}

.feature-card__inner {
  text-align: center;

  @include vp-767 {
    text-align: left;
  }
}

.feature-card__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.feature-card__desc {
  margin: 0;

  .feature-card--bright & {
    .link {
      @extend .link--bright
    }
  }
}
