.calculator {
  margin: 0;
  padding-top: 111px;
  padding-bottom: 111px;

  background-color: $color-ghost-white;

  @include vp-1023 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &--dark {
    color: $color-default-white;

    background-color: $color-paua;
  }
}

.calculator__header {
  max-width: 620px;
  margin-bottom: 57px;

  @include vp-1023 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;

    text-align: center;
  }
}

.calculator__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

.calculator__description {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.calculator__subtitle {
  margin-top: 0;
  margin-bottom: 26px;

  color: $color-governor-bay;
}

.calculator__tabs-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;

  @include rvp-1024 {
    margin-bottom: 63px;
  }
}

.calculator__tabs-list {
  padding-top: 20px;
}

.calculator__tabs-btn {
  &.is-hidden {
    display: none;
  }

  @include vp-1023 {
    display: none;
  }
}

.calculator__form {
  &,
  .custom-input,
  .custom-input input,
  .custom-select,
  .custom-input__label,
  .custom-select__label,
  .custom-select__button,
  .custom-select__icon {
    color: $color-governor-bay;
  }

  .custom-input,
  .custom-select {
    max-width: none;
  }

  .custom-input input {
    text-align: right;
    font-weight: 600;
    padding-right: 24px;
  }

  .custom-input input::placeholder {
    color: rgba($color-governor-bay, .7);
    font-weight: 400;
  }

  .custom-input input,
  .custom-select__button {
    background-color: $color-default-white;
  }

  .custom-input input,
  .custom-select__button,
  .custom-select__list {
    border-color: $color-default-white;
  }

  .custom-select__text,
  .custom-select__list,
  .custom-select__label {
    font-weight: 600;
  }

  .custom-input__label,
  .custom-select__label {
    max-width: 270px;
    flex: 0 0 53%;

    @include rvp-1024 {
      flex: 0 0 41%;
    }

    @include rvp-1240 {
      flex: 0 0 53%;
    }

    @include vp-599 {
      max-width: none;
      flex: initial;
      width: 100%;
    }
  }

  .calculator__select-grid {
    .custom-input__label,
    .custom-select__label {
      max-width: 40px;
      flex: 0 0 40px;

      @include rvp-600 {
        //max-width: 70px;
        //flex: 0 0 70px;
      }
    }
  }
}

.calculator__form-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0 30px;
  margin-bottom: 20px;

  @include rvp-1024 {
    flex-direction: row;
  }
}

.calculator__col {
  max-width: 518px;

  @include rvp-768 {
    flex: 0 0 48%;
  }
}

.calculator__input {
  margin-bottom: 30px;

  .calculator__input {
    margin-bottom: 0;
  }

  @include rvp-600 {
    margin-bottom: 17px;
  }

  &--width-sm {
    .custom-input__field,
    .custom-select__button,
    .custom-select__list,
    .calculator__input {
      width: 100px;
      flex: 0 0 100px;
    }
  }

  &--width-xs {
    .custom-input__field,
    .custom-select__button,
    .custom-select__list,
    .calculator__input {
      width: 70px;
      flex: 0 0 70px;
    }
  }

  &--centered {
    .custom-select__text,
    .custom-select__list {
      text-align: center;
    }
  }
}

.calculator__group {
  .calculator__subtitle {
    margin-bottom: 12px;
  }

  .custom-input,
  .custom-select {
    width: auto;
  }

  @include rvp-600 {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 18px;

    .calculator__subtitle {
      max-width: 230px;
      flex: 0 0 calc(53% - 40px);
      margin-bottom: 0;
    }
  }

  @include rvp-1024 {
    .calculator__subtitle {
      flex: 0 0 calc(41% - 40px);
    }
  }

  @include rvp-1240 {
    .calculator__subtitle {
      flex: 0 0 calc(53% - 40px);
    }
  }
}

.calculator__select-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 0 20px;

  .custom-select--inline {
    display: flex;
    align-items: center;
  }

  .custom-select__label {
    text-align: right;
    margin-right: 12px;
    margin-bottom: 0;
  }

  @include rvp-600 {
    .custom-select__label {
      margin-right: 12px;
    }
  }

  @include vp-599 {
    width: 100%;
    max-width: 286px;
  }
}

.calculator__submit-btn {
  &.is-hidden {
    display: none;
  }

  @include rvp-1024 {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 44px;
  }

  @include rvp-1024 {
    margin-bottom: 63px;
  }
}

.calculator__modal-btn {
  &.is-hidden {
    display: none;
  }

  @include rvp-1024 {
    display: none;
  }
}

.calculator__results {
  margin-top: 60px;

  .calculator__subtitle {
    margin-bottom: 40px;
  }

  @include rvp-1240 {
    margin-top: 80px;
  }
}

.calculator__results-grid {
  max-width: 570px;

  @include rvp-1024 {
    display: grid;
    grid-template-columns: 57.25% 34%;
    justify-content: space-between;
    align-items: baseline;
    max-width: none;
  }
}

.calculator__footnote-list {
  color: $color-wild-blue-yonder;

  @include vp-1023 {
    margin-top: 40px;
  }
}

.calculator__action {
  margin-top: 50px;

  color: $color-wild-blue-yonder;

  text-align: center;

  @include rvp-1240 {
    margin-top: 107px;
  }
}

.calculator__action-description {

  &:not(:first-child) {
    margin-top: 23px;
  }

  &:not(:last-child) {
    margin-bottom: 23px;
  }
}