.materials {
  margin: 0;
  padding: 80px 0 120px;
  background-color: $color-ghost-white;

  @include vp-599 {
    padding: 60px 0 80px;
  }
}

.materials__wrapper {
  max-width: 770px;
  margin: 0 auto;

  text-align: center;

  &:not(:last-child) {
    margin-bottom: 56px;
  }

  @include vp-599 {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.materials__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.materials__paragraph {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.materials__action-btns {
  &:not(:first-child) {
    margin-top: 65px;
  }

  &:not(:last-child) {
    margin-bottom: 65px;
  }

  @include vp-767 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}