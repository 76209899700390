.action-btns {
  display: flex;
  flex-wrap: wrap;

  margin: -16px;
  padding: 0;

  list-style: none;

  &--centered {
    justify-content: center;
  }

  &--centered-tablet {
    @include vp-1023 {
      justify-content: center;
    }
  }

  &--centered-mobile {
    @include vp-767 {
      justify-content: center;
    }
  }

  @include vp-767 {
    margin: -10px -5px;

    &:first-child {
      margin-top: -10px;
    }

    &:last-child {
      margin-bottom: -10px;
    }
  }
}

.action-btns__item{
  padding: 16px;

  @include vp-767 {
    padding: 10px 5px;
  }
}
