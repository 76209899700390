.article-tabs {
  display: grid;
  grid-template-columns: 470fr 670fr;
  gap: 30px;

  @include vp-1023 {
    display: block;
  }
}

.article-tabs .article-tabs__btn-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include vp-1023 {
    margin-bottom: 20px;
  }
}

.article-tabs .article-tabs__btn-item {
  border: 1px solid $color-quartz;

  &:not(:first-child) {
    margin-top: -1px;
  }
}

.article-tabs .article-tabs__btn:not(.btn) {
  position: relative;
  width: 100%;
  padding: 32px;

  text-decoration: none;
  color: $color-jaguar;

  background-color: $color-default-white;

  transition: background-color $trans-default;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) scaleY(1.25) rotate(45deg);

    width: 20px;
    height: 20px;

    background-color: $color-transparent;
    border-top: 1px solid $color-transparent;
    border-right: 1px solid $color-transparent;

    transition:
      background-color $trans-default,
      border-color $trans-default;
  }

  &.is-active {
    background-color: $color-ghost-white;

    &::before {
      background-color: $color-ghost-white;
      border-color: $color-quartz;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      background-color: $color-ghost-white;
    }
  }

  @include vp-1023 {
    padding: 20px;

    &::before {
      content: unset;
    }
  }
}

.article-tabs .article-tabs__btn-title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.article-tabs .article-tabs__btn-paragraph {
  margin: 0;
  &:not(:first-child) {
    margin-top: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.article-tabs .article-tabs__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.article-tabs .article-tabs__item {
  width: 100%;
}

.article-tabs .article-tabs__figure {
  width: 100%;
  max-width: unset;
  margin: 32px 0;

  .article-tabs__img {
    width: 100%;
  }

  .article-tabs__figcaption {
    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    color: $color-comet;
  }
}
