.summary__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @include vp-1023 {
    display: block;
  }
}

.summary__content {
  max-width: 510px;
  padding-right: 40px;

  @include vp-1023 {
    max-width: 600px;
    margin: 0 auto 48px;
    padding-right: 0;
  }
}

.summary__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.summary__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.summary__cover {
  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.summary__cover-img {
  width: 100%;
  height: auto;
}

.summary__features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 70px 30px;

  max-width: 600px;
  margin: 0 auto;
  padding: 0;

  list-style: none;

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 40px 30px;
  }
}

.summary__feature {
  @include vp-767 {
    display: flex;
  }
}
