.custom-toggle {
  position: relative;

  display: flex;
  max-width: 420px;
  width: 100%;
  margin: 16px 0;
  padding: 0;

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;

    padding: 3px 0;
    padding-left: 36px;

    cursor: pointer;

    span {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &--checkbox {
    .custom-toggle__icon {
      position: absolute;
      top: 2px;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;

      color: $color-default-white;

      background-color: $color-transparent;
      border: 1px solid $color-quartz-2;
      border-radius: 6px;

      transition:
        background-color $trans-default,
        border-color $trans-default;
    }

    input:checked + .custom-toggle__icon {
      background-color: $color-governor-bay;
      border-color: $color-governor-bay;
    }
  }

  &--radio {
    .custom-toggle__icon {
      position: absolute;
      top: 2px;
      left: 0;

      width: 24px;
      height: 24px;

      background-color: $color-transparent;
      border: 1px solid $color-quartz-2;
      border-radius: 50%;

      transition: border-color $trans-default;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: 12px;
        height: 12px;

        color: $color-transparent;

        border-radius: 50%;
        transform: translate(-50%, -50%);

        transition: background-color $trans-default;
      }
    }

    input:checked + .custom-toggle__icon {
      border-color: $color-governor-bay;

      &::before {
        background-color: $color-governor-bay;;
      }
    }
  }

  &.is-invalid {
    .custom-toggle__icon {
      border: 1px solid red;
    }

    .custom-toggle__error {
      opacity: 1;

      pointer-events: auto;
    }
  }

  /*&.is-valid {
    label span {
      color: $color-eucalyptus;
    }
  }*/
}

.custom-toggle__icon-img {
  width: 12px;
  height: 12px;
}

.custom-toggle__label {
  color: $color-spun-pearl;

  transition: color $trans-default;

  &:focus,
  &:hover {
    color: $color-jaguar;
  }
}

.custom-toggle__error {
  position: absolute;
  left: 0;
  bottom: -14px;

  font-size: 14px;
  line-height: percentage(20 / 14);

  color: red;

  opacity: 0;

  transition: opacity $trans-default;

  pointer-events: none;
}
