.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  max-width: 420px;
  width: 100%;
  padding: 0;

  label {
    display: flex;
    align-items: center;
    width: 100%;
  }

  input,
  textarea {
    flex-grow: 1;
    min-height: 40px;
    padding: 22px 15px 6px;

    font-size: 18px;
    line-height: percentage(26 / 18);

    border: 1px solid $color-quartz-2;
    border-radius: 8px;
    outline: none;

    transition: border-color $trans-default;

    &:hover,
    &:focus {
      border-color: $color-matterhorn;
    }
  }

  &.is-invalid {
    input {
      border-color: red;

      &:focus,
      &:hover {
        border-color: rgba(red, 0.5);
      }
    }

    .custom-input__error {
      opacity: 1;
    }
  }

  &--inline {
    label {
      @include vp-599 {
        flex-wrap: wrap;
      }
    }

    input,
    textarea {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  &--select {
    .custom-select {
      margin-left: 20px;
    }
  }

  /*&.is-valid {
    input {
      border: 1px solid $color-malachite;

      &:focus,
      &:hover {
        border: 1px solid rgba($color-malachite, 0.5);
      }
    }
  }*/
}

.custom-input__label,
.custom-textarea__label {
  position: absolute;
  top: 32px;
  left: 16px;
  transform: translateY(-50%);
  transform-origin: top left;

  cursor: text;

  color: $color-spun-pearl;

  transition:
    opacity $trans-default,
    top $trans-default,
    transform $trans-default;

  &.is-moved,
  input:focus + &,
  textarea:focus + & {
    top: 10px;
    transform: scale(percentage(2 / 3));

    cursor: auto;

    .custom-input.is-invalid &,
    .custom-textarea.is-invalid & {
      opacity: 0;
    }
  }

  .custom-input--inline &,
  .custom-textarea--inline & {
    position: static;
    transform: none;
    width: 100%;
    margin-bottom: 12px;

    line-height: percentage(22 / 18);

    @include rvp-600 {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
}

.custom-input__field {
  position: relative;
  flex-grow: 1;

  input,
  textarea {
    width: 100%;
  }
}

.custom-input__error {
  position: absolute;
  right: 10px;
  bottom: 2px;

  font-size: 14px;
  line-height: percentage(20 / 14);

  color: red;

  opacity: 0;

  transition: opacity $trans-default;

  pointer-events: none;
}

.custom-input__unit {
  margin-left: 34px;

  font-weight: 600;
}