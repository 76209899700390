.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.8);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
    transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }
}

.modal__wrapper {
  position: relative;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 64px 32px;

  @include vp-767 {
    padding: 48px 16px;
  }

  @include vp-413 {
    padding: 48px 0;
  }

  .modal--lightbox & {
    min-height: 400px;
    height: 100%;
    padding: 32px;

    @include vp-767 {
      padding: 16px;
    }

    @include vp-413 {
      padding: 0;
    }
  }
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;
}

.modal__content {
  position: relative;

  width: 100%;
  max-width: 970px;
  padding: 48px;

  background-color: $color-default-white;
  border-radius: 12px;

  @include vp-767 {
    padding: 32px;
  }

  @include vp-413 {
    padding: 24px;
  }

  .modal--size-sm & {
    max-width: 770px;
  }

  .modal--contact-form & {
    max-width: 670px;
  }

  .modal--lightbox & {
    max-width: unset;
    height: 100%;
    padding: 24px;

    @include vp-767 {
      padding: 16px;
    }

    @include vp-413 {
      padding: 16px;
    }
  }
}

.modal__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  padding: 0;

  color: $color-spun-pearl;

  background: $color-transparent;
  border: none;

  outline: none;
  cursor: pointer;

  transition: color $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus
    &:active {
      color: $color-jaguar;
    }
  }

  @include vp-767 {
    top: 5px;
    right: 5px;
  }

  @include vp-413 {
    top: 0;
    right: 0;
  }
}

.modal__close-btn-icon {
  width: 24px;
  height: 24px;
}

.modal__title {
  margin: 0;

  text-align: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.modal__paragraph {
  margin: 0;

  text-align: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
