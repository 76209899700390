.features {
  margin: 0;
  padding: 80px 0;

  color: $color-default-white;

  background-color: $color-paua;

  &--light {
    color: $color-paua;

    background: none;
  }

  &--spaced {
    padding-top: 122px;
    padding-bottom: 144px;
  }

  @include vp-1023 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.features__content {
  max-width: 770px;
  margin: 0 auto 70px;

  text-align: center;

  @include vp-1023 {
    max-width: 600px;
    margin-bottom: 48px;
  }
}

.features__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.features__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.features__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px 30px;

  margin: 0 auto;
  padding: 0;

  list-style: none;

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);

    max-width: 600px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 40px 30px;
  }
}
