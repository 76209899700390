.slider-dealers {
  position: relative;

  @include vp-1239 {
    margin: 0 calc(-1 * var(--horizontal-padding));
  }
}

.slider-dealers__container {
  @include vp-1239 {
    padding: 0 var(--horizontal-padding);
  }
}

.slider-dealers__wrapper {
  .slider-dealers--low-content & {
    justify-content: center;

    width: calc(100% + 30px);
    margin-right: -30px;
  }

  @include vp-767 {
    .slider-dealers--low-content & {
      width: calc(100% + 20px);
      margin-right: -20px;
    }
  }
}

.slider-dealers__slide {
  height: unset;

  @include vp-599 {
    width: 340px;
  }

  @include vp-413 {
    width: 240px;
  }
}

.slider-dealers__pagination {
  display: none;
  justify-content: center;
  margin-top: 24px;

  @include vp-767 {
    display: flex;
  }
}

.slider-dealers__pagination-bullet {
  width: 8px;
  height: 8px;

  background-color: $color-quartz;
  border-radius: 50%;

  transition: background-color $trans-default;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &--active {
    background-color: $color-governor-bay;
  }
}
