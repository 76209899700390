.knowledge-base-section__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  @include vp-1023 {
    &:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}

.knowledge-base-section__article-links {
  column-count: 2;
  column-gap: 60px;

  margin: 0;
  margin-bottom: -24px;
  padding: 0;

  font-weight: 400;

  list-style: none;

  @include vp-1023 {
    column-count: unset;

    max-width: 660px;
  }
}

.knowledge-base-section__article-link-item {
  break-inside: avoid-column;
  display: inline-grid;
  page-break-inside: avoid;

  @supports (break-inside: avoid-column) {
    display: block;
  }

  margin-bottom: 24px;
}

.knowledge-base-section__article-link {
  position: relative;

  padding-left: 20px;

  color: $color-governor-bay;

  transition: color $trans-default;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;

    width: 8px;
    height: 8px;

    background-color: $color-governor-bay;
    border-radius: 50%;

    transition: background-color $trans-default;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: $color-paua;

      &::before {
        background-color: $color-paua;
      }
    }
  }
}
