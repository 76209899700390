.main-nav {
  font-weight: 400;

  @include vp-1239 {
    position: relative;
    z-index: -1;
  }
}

.main-nav__wrapper {
  @include vp-1239 {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    background-color: rgba($color-default-black, 0.7);
    visibility: hidden;
    opacity: 0;

    transition: opacity $trans-modal,
      visibility $trans-modal;

    pointer-events: none;

    .is-open & {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;

      pointer-events: auto;
    }
  }
}

.main-nav__inner {
  @include vp-1239 {
    position: relative;
    transform: translateY(-100%);

    display: flex;
    flex-shrink: 0;
    justify-content: center;

    width: 100%;

    transition: transform $trans-modal;

    .is-open & {
      transform: translateY(0);
    }
  }
}

.main-nav__overlay {
  @include vp-1239 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }
}

.main-nav__content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include vp-1239 {
    display: block;

    width: 100%;
    padding: 112px var(--horizontal-padding) 48px;
  }

  transition: background-color $trans-modal;

  background-color: $color-default-white;

  .wrapper--transparent-header & {
    background-color: $color-transparent;
  }

  &.is-scrolled {
    background-color: $color-default-white;
  }

  @include vp-1239 {
    .wrapper--transparent-header & {
      background-color: $color-default-white;
    }
  }
}

.main-nav__list {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  &:not(:last-child) {
    margin-right: 40px;
  }

  @include vp-1239 {
    display: block;

    padding: 0;

    font-size: 24px;
    line-height: percentage(28 / 24);

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 48px;
    }
  }
}

.main-nav__item {
  position: relative;

  &:not(:last-child) {
    margin-right: 27px;
  }

  @include vp-1239 {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

.main-nav__link {
  text-decoration: none;
  color: $color-jaguar;

  transition: color $trans-default;

  &:visited {
    color: $color-jaguar;
  }

  &.has-submenu:not([href]) {
    pointer-events: auto;
    cursor: pointer;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      color: $color-governor-bay;
    }
  }

  @include rvp-1240 {
    .wrapper--transparent-header & {
      color: $color-default-white;

      &:visited {
        color: $color-default-white;
      }

      &--no-visited-styles {
        &:visited {
          color: $color-default-white;
        }
      }
    }

    .is-scrolled & {
      color: $color-jaguar;

      &:visited {
        color: $color-jaguar;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus,
        &:active {
          color: $color-governor-bay;
        }
      }
    }
  }
}

.main-nav__sublist {
  margin: 0;
  padding: 0;

  list-style: none;

  @include rvp-1240 {
    visibility: hidden;
    opacity: 0;

    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    transform: translateY(10px);

    width: max-content;
    max-width: 220px;
    margin: 0;
    padding: 16px 20px;

    background-color: $color-default-white;
    border-radius: 8px;
    box-shadow: $shadow-submenu;

    transition:
      visibility $trans-default 0.3s          ,
      opacity $trans-default 0.3s         ,
      transform $trans-default 0.3s         ;

    &::before {
      position: absolute;
      left: 8px;
      bottom: calc(100% - 1px);

      width: 20px;
      height: 6px;

      background-color: $color-default-white;

      clip-path: url("#submenucorner");
      content: "";
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;

      height: 19px;

      content: "";
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      .main-nav__item:hover &,
      .main-nav__item:focus-within & {
        visibility: visible;
        opacity: 1;

        transform: translateY(0);

        transition:
          visibility $trans-default,
          opacity $trans-default,
          transform $trans-default;
      }
    }
  }

  @include vp-1239 {
    padding-top: 16px;
    padding-left: 35px;

    font-size: 18px;
    line-height: percentage(26 / 18);
  }
}

.main-nav__subitem {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  @include vp-1239 {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.main-nav__sublink {
  text-decoration: none;
  color: $color-governor-bay;

  transition: color $trans-default;

  &:visited {
    color: $color-governor-bay;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      color: $color-wild-blue-yonder;
    }
  }

  &:not([href]) {
    color: $color-spun-pearl;
  }

  @include vp-1239 {
    text-decoration: underline;

    &:not([href]) {
      text-decoration: none;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $color-governor-bay;
      }
    }
  }
}

.main-nav__order-btn {
  @include rvp-1240 {
    padding: 11px 23px 7px;
  }
}
