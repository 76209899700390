.slider-photos {
  position: relative;

  margin: 0;

  background-image: $gradient-slider-photos;
}

.slider-photos__container {
  .slider-photos--low-content & {
    width: max-content;
  }
}

.slider-photos__wrapper {
  .slider-photos--low-content & {
    width: max-content;
    margin-right: -30px;
  }
}

.slider-photos__slide {
  width: 470px;
  height: 312px;

  @include vp-767 {
    width: 368px;
    height: 244px;
  }

  @include vp-413 {
    width: 276px;
    height: 183px;
  }
}

.slider-photos__photo {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.slider-photos__controls {
  background-color: $color-ghost-white;
}

.slider-photos__pagination {
  display: none;
  justify-content: center;
  margin-top: 24px;

  @include vp-767 {
    display: flex;
  }
}

.slider-photos__pagination-bullet {
  width: 8px;
  height: 8px;

  background-color: $color-quartz;
  border-radius: 50%;

  transition: background-color $trans-default;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &--active {
    background-color: $color-governor-bay;
  }
}
