.search-prompt {
  position: relative;

  width: 100%;
}

.search-prompt__label {
  display: block;
}

.search-prompt__input {
  @extend .txt;

  display: block;
  width: 100%;
  padding: 18px 88px 18px 32px;

  border: 1px solid $color-quartz;
  border-radius: 32px;
  outline: none;

  transition: border-color $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-governor-bay;
    }
  }

  .search-prompt--size-sm & {
    padding: 12px 64px 12px 20px;

    border-radius: 26px;
  }
}

.search-prompt__submit-btn {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;

  color: $color-spun-pearl;

  background-color: $color-transparent;
  border: none;
  outline: none;

  transition: color $trans-default;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-governor-bay;
    }
  }

  .search-prompt--size-sm & {
    right: 12px;
  }
}
