@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Sofia Pro";

  font-display: swap;
  src:
    url("../fonts/sofia-pro-300.woff2") format("woff2"),
    url("../fonts/sofia-pro-300.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Sofia Pro";

  font-display: swap;
  src:
    url("../fonts/sofia-pro-400.woff2") format("woff2"),
    url("../fonts/sofia-pro-400.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Sofia Pro";

  font-display: swap;
  src:
    url("../fonts/sofia-pro-600.woff2") format("woff2"),
    url("../fonts/sofia-pro-600.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Sofia Pro";

  font-display: swap;
  src:
    url("../fonts/sofia-pro-700.woff2") format("woff2"),
    url("../fonts/sofia-pro-700.woff") format("woff");
}
