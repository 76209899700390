.content {
  h1 {
    margin: 64px 0;
  }

  h2 {
    @extend .h4;

    margin: 48px 0 24px;
  }

  h3 {
    @extend .h5;

    margin: 24px 0 12px;
  }

  h4,
  h5 {
    @extend .h6;

    margin: 24px 0 12px;
  }

  h6 {
    margin: 24px 0 12px;
  }

  p {
    margin: 12px 0;
  }

  ul,
  ol {
    margin: 12px 0;
    padding-left: 30px;
  }

  table {
    width: 100%;
    margin: 40px 0;

    border-collapse: collapse;

    th {
      padding: 0 16px 14px;

      font-weight: 600;
      text-align: left;
      color: $color-comet;

      border-bottom: 4px solid $color-comet;
    }

    td {
      padding: 20px 16px 18px;
    }

    tr:nth-child(odd) td {
      background-color: $color-ghost-white;
    }
  }

  blockquote {
    @extend .txt-lg;

    position: relative;

    max-width: 1080px;
    margin: 40px 0;
    padding: 0 60px;

    font-weight: 400;

    &:before {
      position: absolute;
      top: 0;
      left: 31px;
      bottom: 0;

      width: 8px;

      background-color: $color-governor-bay;

      content: "";
    }
  }

  a {
    @extend .link;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  table,
  blockquote {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
