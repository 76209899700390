.chaned-structure {
  .chaned-structure__header {
    margin: 0;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include vp-599 {
      &:not(:first-child) {
        margin-top: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .chaned-structure__paragraph {
    margin: 0;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .chaned-structure__block {
    position: relative;
    margin-left: 30px;
    padding-left: 68px;

    border-left: 2px solid $color-quartz-2;

    &:last-child {
      border-color: $color-transparent;
    }

    &:not(:first-child) {
      padding-top: 16px;
    }

    &:not(:last-child) {
      padding-bottom: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -3px;

      width: 44px;
      height: 39px;

      background-image: url("../../img/svg/chain-corner.svg");
      background-size: 42px 37px;
      background-position: center;
      background-repeat: no-repeat;
    }

    .chaned-structure__header {
      margin: 0;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .chaned-structure__paragraph {
      margin: 0;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    @include vp-599 {
      margin-left: 10px;
      padding-left: 29px;

      border-width: 1px;

      &:not(:first-child) {
        padding-top: 8px;
      }

      &:not(:last-child) {
        padding-bottom: 8px;
      }

      &::before {
        top: -2px;
        left: -2px;

        width: 22px;
        height: 21px;

        background-size: 21px 19px;
      }
    }
  }
}
