.slider-product-card {
  position: relative;

  padding-bottom: 20px;
}

.slider-product-card__slide {
  position: relative;

  width: 100%;
  height: unset;
  padding-top: percentage(250 / 270);
}

.slider-product-card__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  object-fit: contain;
}

.slider-product-card__pagination.swiper-pagination-horizontal {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
}

.slider-product-card__pagination-bullet {
  width: 12px;
  height: 4px;

  background-color: $color-quartz;
  border-radius: 2px;

  transition: background-color $trans-default;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &--active {
    background-color: $color-governor-bay;
  }
}
