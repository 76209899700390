.product-hero {
  position: relative;

  margin: 0;
  padding-top: percentage(680 / 1920);

  color: $color-default-white;

  background-color: $color-paua;

  @include vp-1919 {
    padding-top: 680px;
  }

  @include vp-767 {
    padding-top: percentage(400 / 414);
  }
}

.product-hero__cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  @include vp-1919 {
    height: 680px;
  }

  @include vp-767 {
    height: auto;
  }
}

.product-hero__cover-img {
  width: 100%;
  height: 100%;

  object-fit: cover;

  @include vp-1239 {
    object-position: 25% center;
  }

  @include vp-767 {
    object-position: unset;
  }
}

.product-hero__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;

  @include vp-1023 {
    position: static;
  }
}

.product-hero__content {
  width: 60%;
  min-width: 400px;
  margin-left: auto;
  padding: 140px 0 140px 30px;

  @include vp-1919 {
    width: 50%;
    padding: 115px 0 115px 15px;
  }

  @include vp-1023 {
    width: 100%;
    min-width: unset;
    padding: 48px 0 56px;
  }
}

.product-hero__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.product-hero__slogan {
  margin: 0;

  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.product-hero__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.product-hero__action-btns {
  &:not(:first-child) {
    margin-top: 48px;
  }

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  @include vp-767 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
