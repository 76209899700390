.products-compare__intro {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  margin-bottom: 110px;

  &:last-child {
    margin-bottom: 70px;
  }

  @include vp-1023 {
    display: block;
  }
}

.products-compare__wrapper {
  max-width: 540px;
  padding-right: 70px;

  @include vp-1023 {
    max-width: 600px;
    margin: 0 auto 70px;
    padding: 0;

    text-align: center;
  }
}

.products-compare__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.products-compare__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}

.products-compare__action-btns {
  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.products-compare__product-inner {
  position: relative;
}

.products-compare__cover {
  margin: -70px 0;

  @include vp-1023 {
    max-width: 600px;
    margin: 0 auto;
  }
}

.products-compare__cover-img {
  max-width: 100%;
  height: auto;
}
