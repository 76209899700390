// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
@import "vendor/swiper";
// Tippy.js
@import "vendor/tippy";
@import "vendor/tippy-theme-light";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/typography";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/btn";
@import "blocks/link";
@import "blocks/content";
@import "blocks/tooltip-btn";
@import "blocks/nav-btn";
@import "blocks/modal";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/data-tabs";
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/product-hero";
@import "blocks/action-btns";
@import "blocks/summary";
@import "blocks/feature-card";
@import "blocks/features";
@import "blocks/details";
@import "blocks/slider-photos";
@import "blocks/specifications";
@import "blocks/slider-tabs";
@import "blocks/complimentary-product";
@import "blocks/downloads";
@import "blocks/bread-crumbs";
@import "blocks/dealers";
@import "blocks/dealer-card";
@import "blocks/slider-products";
@import "blocks/slider-product-card";
@import "blocks/product-card";
@import "blocks/modal-order-form";
@import "blocks/contacts";
@import "blocks/hero";
@import "blocks/products-compare";
@import "blocks/materials";
@import "blocks/slider-materials";
@import "blocks/material-card";
@import "blocks/software";
@import "blocks/document-link";
@import "blocks/slider-dealers";
@import "blocks/modal-lightbox";
@import "blocks/modal-contact-form";
@import "blocks/ecosystem";
@import "blocks/registration";
@import "blocks/knowledge-base-hero";
@import "blocks/search-prompt";
@import "blocks/knowledge-base-sections";
@import "blocks/knowledge-base-header";
@import "blocks/knowledge-base-section";
@import "blocks/data-toc.scss";
@import "blocks/article";
@import "blocks/article-rating";
@import "blocks/banner";
@import "blocks/article-tabs";
@import "blocks/chaned-structure";
@import "blocks/cover-hero";
@import "blocks/slider-applications";
@import "blocks/contacts-form";
@import "blocks/tabs-link";
@import "blocks/calculator";
@import "blocks/footnote-list";
@import "blocks/result-list";

// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
