// Colors
// ---------------------------------

// Default

$color-default-black:               #000000;
$color-default-white:               #ffffff;
$color-transparent:                 rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-jaguar:                      #191833;
$color-matterhorn:                  #4d4d4d;
$color-comet:                       #6a697a;
$color-nobel:                       #999999;
$color-deluge:                      #7b7a92;
$color-spun-pearl:                  #a3a3ad;
$color-ghost:                       #b2b2c0;
$color-quartz-2:                    #d3d3db;
$color-quartz:                      #e0e0e4;
$color-ghost-white-2:               #f1f1f3;
$color-ghost-white:                 #f4f4f6;

$color-paua:                        #23224a;
$color-governor-bay:                #4652a0;
$color-wild-blue-yonder:            #7b80bd;
$color-echo-blue:                   #a2a8cf;

$color-dark-tangerine:              #eeb210;
$color-moon-yellow:                 #fcbe18;
$color-vis-vis:                     #fedf8b;
$color-orioles-orange:              #fc4f18;

// Gradients

$gradient-slider-photos:            linear-gradient(
                                      to bottom,
                                      $color-transparent 50%,
                                      $color-ghost-white 50%);

// Shadows

$shadow-header:                     0 2px 4px 0 rgba($color-default-black, 0.08);
$shadow-header-transparent:         0 2px 4px 0 $color-transparent;
$shadow-submenu:
  0 0 4px 0 rgba($color-default-black, 0.04),
  0 4px 32px 0 rgba($color-default-black, 0.16);

// Typography
// ---------------------------------

$ff-sofia-pro:                    "Sofia Pro", "Arial", sans-serif;

// Animation
// ---------------------------------

$trans-default:                     0.3s ease;
$trans-burger:                      0.6s cubic-bezier(0.75, -0.5, 0.25, 1.5);
$trans-modal:                       0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600:                         0.6s ease;

// Viewports
// ---------------------------------

$vp-320:                            320px;
$vp-414:                            414px;
$vp-600:                            600px;
$vp-768:                            768px;
$vp-1024:                           1024px;
$vp-1240:                           1240px;
$vp-1920:                           1920px;

// Retina
// ---------------------------------

$retina-dpi:                        144dpi;
$retina-dppx:                       1.5dppx;
