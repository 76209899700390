.footer {
  padding: 32px 0;

  font-weight: 400;
  font-size: 16px;
  line-height: percentage(26 / 16);

  color: $color-ghost;

  background-color: $color-paua;
}

.footer__inner {
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: flex-end;
  justify-content: space-between;

  margin: -12px -24px;

  @include vp-1239 {
    margin: -12px -18px;
  }

  @include vp-1023 {
    margin: -12px;
  }

  @include vp-767 {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    margin: 0;
  }
}

.footer__menu-list {
  display: flex;
  flex-wrap: wrap;

  margin: -6px -24px;
  padding: 12px 24px;

  list-style: none;

  @include vp-1239 {
    margin: -6px -18px;
    padding: 12px 18px;
  }

  @include vp-1023 {
    margin: -6px -12px;
    padding: 12px;
  }

  @include vp-767 {
    margin-bottom: 24px;
    padding: 0;
  }
}

.footer__menu-link {
  padding: 6px 24px;

  text-decoration: none;
  color: $color-ghost;

  transition: color $trans-default;

  text-transform: uppercase;

  &:visited {
    color: $color-ghost;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      color: $color-moon-yellow;
    }
  }

  @include vp-1239 {
    padding: 6px 18px;
  }

  @include vp-1023 {
    padding: 6px 12px;
  }

}

.footer__copyright {
  flex-shrink: 0;

  margin-right: 24px;
  padding: 12px 24px;

  @include vp-1239 {
    padding: 12px 18px;
  }

  @include vp-1023 {
    padding: 12px;
  }

  @include vp-767 {
    margin: 0;
    padding: 0;
  }
}
