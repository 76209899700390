.hero {
  position: relative;

  margin: 0;
  padding-top: percentage(800 / 1920);

  color: $color-default-white;

  background-color: $color-nobel;

  @include vp-1919 {
    padding-top: 800px;
  }

  @include vp-1023 {
    padding-top: 600px;
  }

  @include vp-767 {
    padding-top: 400px;
  }
}

.hero__cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  @include vp-1919 {
    height: 800px;
  }

  @include vp-1023 {
    height: 600px;
  }

  @include vp-767 {
    height: 400px;
  }
}

.hero__cover-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: left center;
}

.hero__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  width: 100%;
  height: 100%;
  padding: 70px 0;

  @include vp-767 {
    padding: 40px 0;
  }

  @include vp-767 {
    padding: 40px 0;
  }

  @include vp-599 {
    padding: 30px 0;
  }

  @include vp-413 {
    padding: 20px 0;
  }
}

.hero__container {
  display: flex;
}

.hero__content {
  max-width: 570px;
  margin: auto;
  padding-top: var(--header-height);

  text-align: center;

  @include vp-767 {
    max-width: 460px;;
  }
}

.hero__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.hero__paragraph {
  margin: 16px 0;

  @include vp-767 {
    margin: 12px 0;
  }
}
