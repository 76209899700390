.modall-lightbox {
  width: 100%;
  height: 100%;
}

.modall-lightbox__img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}
