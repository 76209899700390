.bread-crumbs {
  margin: 32px 0 20px;

  font-weight: 300;
  font-size: 15px;
  line-height: percentage(26 / 15);
  color: $color-spun-pearl;

  &--element {
    margin: 0;
  }
}

.bread-crumbs__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.bread-crumbs__item {
  display: inline-block;

  &:not(:last-child) {
    &::after {
      content: ">";
    }
  }
}

.bread-crumbs__link {
  display: inline-block;

  color: $color-spun-pearl;

  &:visited {
    color: $color-spun-pearl;
  }
}
