.article-rating {
  margin: 0;
  padding: 36px 0;

  font-weight: 600;
  font-size: 26px;
  line-height: percentage(32 / 26);

  background-color: $color-ghost-white-2;

  &:not(:first-child) {
    margin-top: 110px;
  }

  &:not(:last-child) {
    margin-bottom: 110px;
  }
}

.article-rating__fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

.article-rating__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include vp-599 {
    flex-direction: column;
  }
}

.article-rating__caption {
  flex-shrink: 0;
  margin-top: 8px;
  margin-right: 24px;

  @include vp-599 {
    margin: 0;
    margin-bottom: 12px;
  }
}

.article-rating__inner {
  display: flex;
  align-items: center;
}

.article-rating__group {
  flex-shrink: 0;
  display: flex;
}

.article-rating__label {
  display: block;

  color: $color-moon-yellow;

  .article-rating__fieldset:not(:disabled) & {
    cursor: pointer;
  }
}

.article-rating__star {
  display: block;
  width: 40px;
  height: 40px;
}

input:checked ~ .article-rating__label {
  color: $color-quartz-2;
}

.article-rating__fieldset:not(:disabled) .article-rating__group:hover .article-rating__label {
  color: $color-moon-yellow;
}

.article-rating__fieldset:not(:disabled) input:hover ~ .article-rating__label,
.article-rating__fieldset:not(:disabled) .article-rating__group:hover input:hover ~ .article-rating__label {
  color: $color-quartz-2;
}

.article-rating__current-mark  {
  flex-shrink: 0;
  margin-top: 8px;
  margin-left: 24px;
}
