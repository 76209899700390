.knowledge-base-hero {
  margin: 0;

  background-color: $color-ghost-white-2;
}

.knowledge-base-hero__wrapper {
  padding: 40px 0 48px;

  background-image: url("../img/svg/book.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 470px 470px;

  @include vp-767 {
    background: none;
  }
}

.knowledge-base-hero__bread-crumbs {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.knowledge-base-hero__title{
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.knowledge-base-hero__search {
  max-width: 870px;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
