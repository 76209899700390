.knowledge-base-header {
  margin: 0;
  padding: 20px;

  background-color: $color-ghost-white-2;
}

.knowledge-base-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.knowledge-base-header__return-link {
  display: flex;

  font-weight: 400;
  text-decoration: none;
  color: $color-governor-bay;

  transition: color $trans-default;

  &:visited {
    color: $color-governor-bay;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      color: $color-paua;
    }
  }
}

.knowledge-base-header__return-link-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.knowledge-base-header__search {
  width: 100%;
  max-width: 640px;
}
