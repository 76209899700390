.banner {
  display: flex;
  align-items: flex-start;
  padding: 40px;
  background: $color-ghost-white-2;
  border-radius: 4px;

  @include vp-767 {
    padding: 24px;
  }

  @include vp-599 {
    display: block;
  }
}

.banner__cover {
  flex-shrink: 0;

  width: 200px;
  margin-right: 40px;

  @include vp-767 {
    margin-right: 32px;
  }

  @include vp-599 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
}

.banner .banner__header {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.banner .banner__paragraph {
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:not(:first-child) {
    margin-top: 12px;
  }
}

.banner__btn {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
}
