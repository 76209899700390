.link {
  display: inline-block;

  color: $color-governor-bay;

  outline: none;

  &:visited {
    color: $color-wild-blue-yonder;
  }

  &--no-visited-styles {
    &:visited {
      color: $color-governor-bay;
    }
  }

  &:not([href]) {
    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &--bright {
    color: $color-moon-yellow;

    &:visited {
      color: $color-vis-vis;
    }

    &--no-visited-styles {
      &:visited {
        color: $color-moon-yellow;
      }
    }
  }

  &--light {
    color: $color-default-white;

    &:visited {
      color: $color-default-white;
    }

    &--no-visited-styles {
      &:visited {
        color: $color-default-white;
      }
    }
  }
}
