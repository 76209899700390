.container {
  --horizontal-padding: 35px;

  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 var(--horizontal-padding);

  @include vp-767 {
    --horizontal-padding: 24px;
  }

  @include vp-413 {
    --horizontal-padding: 16px;
  }
}
