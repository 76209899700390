//Headers
// ---------------------------------

h1.h-promo,
h2.h-promo,
h3.h-promo {
  font-weight: 700;
  font-size: 56px;
  line-height: percentage(56 / 56);

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(40 / 40);
  }
}

h1,
.h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: percentage(48 / 48);

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(40 / 40);
  }
}

h2,
.h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: percentage(40 / 40);
}

h3,
.h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: percentage(32 / 32);
}

h4,
.h4 {
  font-weight: 600;
  font-size: 26px;
  line-height: percentage(26 / 26);
}

h5,
.h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: percentage(28 / 20);
}

h6,
.h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: percentage(26 / 18);
}

// Text
// ---------------------------------

.txt {
  font-size: 18px;
  line-height: percentage(26 / 18);
}

.txt-lg {
  font-size: 20px;
  line-height: percentage(28 / 20);
}

.txt-md {
  font-size: 16px;
  line-height: percentage(24 / 16);
}
