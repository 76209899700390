.registration {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.registration__input {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.registration__submit-btn {
  display: flex;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}
