.btn {
  display: inline-flex;
  padding: 19px 31px 15px;

  text-decoration: none;
  color: $color-jaguar;

  font-weight: 400;
  font-size: 20px;
  line-height: percentage(28 / 20);

  background-color: $color-moon-yellow;
  border: 1px solid $color-moon-yellow;
  border-radius: 100px;
  cursor: pointer;

  transition:
    color $trans-default,
    background-color $trans-default,
    border-color $trans-default;

  &:disabled {
    color: $color-spun-pearl;
    background-color: $color-quartz;
    border-color: $color-quartz;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus,
    &:active {
      background-color: $color-dark-tangerine;
      border-color: $color-dark-tangerine;
    }
  }

  &--secondary {
    background-color: $color-default-white;
    border-color: $color-quartz-2;

    &:disabled {
      color: $color-quartz-2;
      background-color: $color-default-white;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        background-color: $color-default-white;
        border-color: $color-paua;
      }
    }
  }

  &--secondary-inverted {
    color: $color-default-white;
    background-color: $color-paua;
    border-color: $color-default-white;

    &:disabled {
      color: $color-governor-bay;
      background-color: $color-paua;
      border-color: $color-governor-bay;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        background-color: $color-paua;
        border-color: $color-governor-bay;
      }
    }
  }

  &--accent {
    color: $color-default-white;
    background-color: $color-governor-bay;
    border-color: $color-governor-bay;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        background-color: $color-wild-blue-yonder;
        border-color: $color-wild-blue-yonder;
      }
    }
  }

  &--info-outline {
    color: $color-wild-blue-yonder;
    background: none;
    border-color: $color-wild-blue-yonder;
    border-width: 2px;
    padding-top: 18px;
    padding-bottom: 14px;

    &:disabled {
      color: $color-quartz-2;
      border-color: $color-quartz-2;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        color: $color-default-white;
        background-color: $color-wild-blue-yonder;
        border-color: $color-wild-blue-yonder;
      }
    }
  }

  &--size-sm {
    padding: 11px 23px 7px;
  }
}
