@mixin vp-1919 {
  @media (max-width: $vp-1920 - 1) {
    @content;
  }
}

@mixin vp-1239 {
  @media (max-width: $vp-1240 - 1) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: $vp-1024 - 1) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: $vp-768 - 1) {
    @content;
  }
}

@mixin vp-599 {
  @media (max-width: $vp-600 - 1) {
    @content;
  }
}

@mixin vp-413 {
  @media (max-width: $vp-414 - 1) {
    @content;
  }
}

@mixin rvp-600 {
  @media (min-width: $vp-600) {
    @content;
  }
}

@mixin rvp-768 {
  @media (min-width: $vp-768) {
    @content;
  }
}

@mixin rvp-1024 {
  @media (min-width: $vp-1024) {
    @content;
  }
}

@mixin rvp-1240 {
  @media (min-width: $vp-1240) {
    @content;
  }
}
