.dealers {
  &--slider {
    margin: 0;
    padding: 80px 0 120px;
    background-color: $color-ghost-white;

    @include vp-599 {
      padding: 60px 0 80px;
    }
  }
}

.dealers__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  .dealers--slider & {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);

    &:not(:last-child) {
      margin-bottom: 48px;
    }

    .dealers--slider & {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

.dealers__regions {
  margin: 0;
  padding: 0;

  list-style: none;
}

.dealers__region {
  &:not(:last-child) {
    margin-bottom: 64px;
  }
}

.dealers__region-title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 26px;
  }
}

.dealers__dealer-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  margin: 0;
  padding: 0;

  list-style: none;

  @include vp-1239 {
    grid-template-columns: repeat(3, 1fr);
  }

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-767 {
    gap: 20px;
  }

  @include vp-599 {
    grid-template-columns: 1fr;
  }
}
