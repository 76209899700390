.article {
  h1 {
    margin: 0;
  }

  h2 {
    margin: 56px 0 32px;
  }

  h3 {
    margin: 56px 0 32px;
  }

  h4 {
    margin: 40px 0 24px;
  }

  h5,
  h6 {
    margin: 32px 0 20px;
  }

  p {
    @extend .txt-lg;

    text-align: justify;

    margin: 28px 0;
  }

  a:not(.btn) {
    @extend .link;
  }

  ol {
    counter-reset: olist;

    margin: 32px 0;
    padding: 0;

    list-style: none;

    li {
      position: relative;

      padding-left: 56px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &::before {
        counter-increment: olist;
        content: counter(olist);
        position: absolute;
        top: 0;
        left: 0;

        padding: 4px;
        padding-bottom: 2px;
        width: 32px;
        height: 32px;

        font-weight: 600;
        text-align: center;
        color: $color-default-white;

        background-color: $color-jaguar;
        border-radius: 50%;
      }
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  table,
  ol,
  figure,
  figcaption {
    max-width: 870px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    width: 100%;
    max-width: unset;
    min-width: 700px;
    margin: 48px 0;

    border-collapse: collapse;

    th {
      padding: 16px;

      font-weight: 600;
      text-align: left;

      background-color: $color-ghost-white-2;
      border-bottom: 1px solid $color-quartz;
    }

    td {
      padding: 16px;

      border-bottom: 1px solid $color-quartz;
    }
  }

  figure {
    width: 100%;
    margin: 32px 0;

    img {
      width: 100%;
    }

    figcaption {
      margin: 16px 0;

      color: $color-comet;
    }
  }
}

.article__wrapper {
  max-width: 870px;
}

.article__header {
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @include vp-599 {
    flex-direction: column-reverse;
  }
}

.article__rating {
  flex-shrink: 0;
  align-self: flex-start;
  align-items: center;
  display: flex;
  align-items: center;
  margin-left: 40px;

  font-weight: 600;
  font-size: 26px;
  line-height: percentage(32 / 26);

  @include vp-599 {
    align-self: flex-end;
    margin-bottom: 12px;
  }
}

.article__rating-star {
  width: 32px;
  height: 32px;
  margin-right: 8px;

  color: $color-moon-yellow;
}

.article__content {
  margin: 0;
  padding: 0;

  list-style: none;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 56px;
    padding-bottom: 40px;

    border-bottom: 1px solid $color-quartz-2;
  }

  & > li,
  & > div {
    &:not(:last-child) {
      margin-bottom: 9px;
    }

    & > a {
      position: relative;

      padding-left: 30px;

      color: $color-comet;

      transition: color $trans-default;

      &::before {
        content: "";
        position: absolute;
        top: 9px;
        left: 10px;

        width: 8px;
        height: 8px;

        background-color: $color-comet;
        border-radius: 50%;

        transition: background-color $trans-default;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
          color: $color-governor-bay;

          &::before {
            background-color: $color-governor-bay;
          }
        }
      }
    }
  }
}

.article__aside-wrapper {
  display: grid;
  grid-template-columns: 1fr 240px;
  align-items: start;
  gap: 40px 60px;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-1239 {
    display: block;
  }
}

.article__aside {
  max-width: 870px;
  padding: 24px;

  background-color: $color-ghost-white;
  border-radius: 4px;

  @include vp-1239 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.article .article__file-link {
  @extend .link--no-visited-styles;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;

  @include vp-1239 {
    flex-direction: row;
    width: fit-content;

    text-align: left;
  }
}

.article__file-link-icon {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;

  @include vp-1239 {
    align-self: flex-start;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}

.article__attention {
  max-width: 870px;
  padding: 48px;
  padding-left: 136px;

  background-color: $color-moon-yellow;
  background-image: url("../img/svg/icon-warning.svg");
  background-size: 48px 48px;
  background-position: 48px 48px;
  background-repeat: no-repeat;
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 48px;
  }

  &:not(:last-child) {
    margin-bottom: 56px;
  }

  @include vp-599 {
    padding: 32px;
    padding-top: 104px;

    background-position: 32px 32px;
  }

  @include vp-413 {
    padding: 24px;
    padding-top: 88px;

    background-position: 24px 24px;
  }
}

.article__ordered-headers-block {
  counter-reset: oheaders;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.article__ordered-header {
  position: relative;

  padding-left: 56px;

  &::before {
    @extend .txt-lg;

    counter-increment: oheaders;
    content: counter(oheaders);
    position: absolute;
    top: 0;
    left: 0;

    padding: 4px;
    padding-bottom: 2px;
    width: 32px;
    height: 32px;

    font-weight: 600;
    text-align: center;
    color: $color-default-white;

    background-color: $color-governor-bay;
    border-radius: 50%;
  }
}

.article__cols2-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 30px;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }
}

.article__links {
  max-width: 870px;
  padding: 40px;

  background-color: $color-ghost-white;
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 56px;
  }

  &:not(:last-child) {
    margin-bottom: 56px;
  }

  @include vp-767 {
    padding: 24px;

    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}

.article .article__links-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &:not(:first-child) {
    margin-top: 24px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.article .article__links-item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.article .article__links-link {
  @extend .link--no-visited-styles;

  display: flex;
  align-items: center;
  width: fit-content;
}

.article__links-icon {
  align-self: flex-start;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.article__yt-video {
  max-width: 870px;
}

.article__yt-video-wrapper {
  position: relative;

  width: 100%;
  padding-top: percentage(9 / 16);

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

.article__table {
  width: calc(100% + 2 * 35px);
  margin: 0 -35px;
  padding: 0 35px;

  overflow-y: auto;

  @include vp-767 {
    width: calc(100% + 2 * 24px);
    margin: 0 -24px;
    padding: 0 24px;
  }

  @include vp-413 {
    width: calc(100% + 2 * 16px);
    margin: 0 -16px;
    padding: 0 16px;
  }
}

.article__feature-list {
  max-width: 870px;
  margin: 0;
  padding: 0;

  list-style: none;

  &:not(:first-child) {
    margin-top: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.article__feature-item {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.article__feature-icon {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 32px;

  object-fit: contain;

  @include vp-599 {
    width: 64px;
    height: 64px;
    margin-right: 24px;
  }
}

.article__feature-text {
  padding: 10px 0;
}

.article__banner {
  max-width: 870px;

  &:not(:last-child) {
    margin-bottom: 56px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }
}

.article__tabs {
  &:not(:first-child) {
    margin-top: 56px;
  }

  &:not(:last-child) {
    margin-bottom: 56px;
  }
}

.article__chaned-structure {
  max-width: 870px;
  &:not(:first-child) {
    margin-top: 56px;
  }

  &:not(:last-child) {
    margin-bottom: 56px;
  }
}
