.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  background-color: $color-default-white;
  box-shadow: $shadow-header;

  transition:
    background-color $trans-modal,
    box-shadow $trans-modal;

  .wrapper--transparent-header & {
    background-color: $color-transparent;
    box-shadow: $shadow-header-transparent;
  }

  &.is-scrolled {
    background-color: $color-default-white;
    box-shadow: $shadow-header;
  }

  @include vp-1239 {
    box-shadow: none;

    .wrapper--transparent-header & {
      box-shadow: none;
    }

    &.is-scrolled {
      box-shadow: none;
    }
  }
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px var(--horizontal-padding);

  @include vp-1239 {
    display: block;

    padding: 0 var(--horizontal-padding);
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $color-default-white;

  transition: none;

  .wrapper--transparent-header & {
    background-color: $color-transparent;
  }

  .is-scrolled & {
    background-color: $color-default-white;

    transition: none;

    transition: background-color $trans-modal 0.6s;
  }

  @include vp-1239 {
    position: relative;
    z-index: 2;

    margin: 0 calc(-1 * var(--horizontal-padding));
    padding: 12px var(--horizontal-padding);

    box-shadow: $shadow-header;

    transition:
      background-color $trans-modal,
      box-shadow $trans-modal;

    .wrapper--transparent-header & {
      box-shadow: $shadow-header-transparent;
    }

    .is-scrolled & {
      box-shadow: $shadow-header;
    }

    .menu-is-open & {
      box-shadow: $shadow-header;
      background-color: $color-default-white;
    }
  }
}

.header__logo {
  flex-shrink: 0;

  margin-right: 40px;

  @include vp-1239 {
    margin-right: auto;
  }
}

.header__logo-img {
  width: 130px;
}

.header__menu-toggle {
  position: relative;
  display: none;

  width: 44px;
  height: 44px;
  margin-left: 25px;
  padding: 0;

  background-color: $color-transparent;
  border: none;
  outline: none;

  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;

    width: 30px;
    height: 4px;

    background-color: $color-governor-bay;
    border-radius: 2px;

    transition:
      background-color $trans-modal,
      transform $trans-modal;
  }

  &::before {
    transform: translate(-50%, calc(-50% - 9px));
  }

  &::after {
    transform: translate(-50%, calc(-50% + 9px));
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover::before,
    &:hover::after,
    &:focus::before,
    &:focus::after {
      background-color: $color-moon-yellow;
    }
  }

  &.is-open::before {
    transform: translate( -50%, -50%) rotate(45deg);
  }

  &.is-open::after {
    transform: translate( -50%, -50%) rotate(135deg);
  }

  @include vp-1239 {
    display: block;
  }
}

.header__menu-toggle-line {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50%);

  width: 30px;
  height: 4px;

  background-color: $color-governor-bay;
  border-radius: 2px;

  transition:
    background-color $trans-modal,
    transform $trans-burger;

  @media (hover: hover), screen and (min-width: 0\0) {
    .header__menu-toggle:hover &,
    .header__menu-toggle:focus & {
      background-color: $color-moon-yellow;
    }
  }

  .is-open & {
    transform: translate( -50%, -50%) scaleX(0);
  }
}
