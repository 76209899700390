.slider-products {
  position: relative;

  @include vp-1239 {
    margin: 0 calc(-1 * var(--horizontal-padding));
  }

  .modal & {
    margin: 0 -48px;

    @include vp-1239 {
      margin: 0 -48px;
    }

    @include vp-767 {
      margin: 0 -32px;
    }

    @include vp-413 {
      margin: 0 -24px;
    }
  }
}

.slider-products__container {
  @include vp-1239 {
    padding: 0 var(--horizontal-padding);
  }

  .modal & {
    padding: 0 48px;

    @include vp-1239 {
      padding: 0 48px;
    }

    @include vp-767 {
      padding: 0 32px;
    }

    @include vp-413 {
      padding: 0 24px;
    }
  }
}

.slider-products__wrapper {
  .slider-products--low-content & {
    justify-content: center;

    width: calc(100% + 30px);
    margin-right: -30px;
  }

  @include vp-767 {
    .slider-products--low-content & {
      width: calc(100% + 20px);
      margin-right: -20px;
    }
  }
}

.slider-products__slide {
  height: unset;

  @include vp-599 {
    width: 200px;
  }
}

.slider-products__pagination {
  display: none;
  justify-content: center;
  margin-top: 24px;

  @include vp-767 {
    display: flex;
  }
}

.slider-products__pagination-bullet {
  width: 8px;
  height: 8px;

  background-color: $color-quartz;
  border-radius: 50%;

  transition: background-color $trans-default;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &--active {
    background-color: $color-governor-bay;
  }
}
