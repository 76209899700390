.modal-order-form {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.modal-order-form__cover {
  flex-shrink: 0;

  width: 220px;
  height: auto;
  max-height: 300px;
  margin-right: 40px;

  @include vp-767 {
    width: 140px;
    max-height: 200px;
    margin-right: 30px;
  }

  @include vp-599 {
    display: none;
  }
}

.modal-order-form__cover-img {
  width: auto;
  height: auto;
  margin: 0 auto;

  max-width: 100%;
  max-height: 100%;

  object-fit: contain;
}

.modal-order-form__input {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.modal-order-form__submit-btn {
  display: flex;
  margin-left: auto;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}
