.material-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.material-card__cover {
  position: relative;

  width: 100%;
  padding-top: percentage(268 / 370);

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.material-card__cover-img {
  position: absolute;
  top: 0;left: 0;

  width: auto;
  height: auto;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.material-card__inner {
  flex-grow: 1;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.material-card__material-link {
  @extend .h4;

  font-weight: 400;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.material-card__title {
  margin: 0;

  font-weight: 400;
}

.material-card__desc {
  margin: 0;

  color: $color-comet;

  &:not(:last-child) {
    margin-bottom: 22px;
  }
}
