.specifications {
  margin: 0;
  padding: 120px 0 80px;
  background-color: $color-ghost-white;

  &--modal {
    padding: 0;
    background-color: $color-transparent;
  }
}

.specifications__wrapper {
  margin: 0 calc(-1 * var(--horizontal-padding));

  .specifications--modal & {
    margin: 0 -48px;
  }

  @include vp-767 {
    .specifications--modal & {
      margin: 0 -32px;
    }
  }

  @include vp-413 {
    .specifications--modal & {
      margin: 0 -24px;
    }
  }
}

.specifications__title {
  margin: 0 var(--horizontal-padding);

  text-align: center;

  &:not(:last-child) {
    margin-bottom: 72px;
  }

  .specifications--modal & {
    margin: 0 48px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  @include vp-767 {
    .specifications--modal & {
      margin: 0 32px;
    }
  }

  @include vp-599 {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    .specifications--modal & {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  @include vp-413 {
    .specifications--modal & {
      margin: 0 24px;
    }
  }
}

.specifications__tabs-btns {
  display: none;
  margin-bottom: 40px;

  @include vp-599 {
    display: block;
  }
}

.specifications__tabs-wrapper {
  margin: 0 var(--horizontal-padding);

  .specifications--modal & {
    margin: 0 48px;
  }

  @include vp-767 {
    .specifications--modal & {
      margin: 0 32px;
    }
  }

  @include vp-413 {
    .specifications--modal & {
      margin: 0 24px;
    }
  }
}

.specifications__categories {
  column-count: 3;
  column-gap: 30px;

  margin: 0;
  padding: 0;

  list-style: none;

  @include vp-1023 {
    .specifications--modal & {
      column-count: 2;
    }
  }

  @include vp-767 {
    column-count: 2;

    .specifications--modal & {
      column-count: 2;
    }
  }

  @include vp-599 {
    column-count: unset;

    .specifications--modal & {
      column-count: unset;
    }
  }
}

.specifications__category {
  break-inside: avoid-column;
  page-break-inside: avoid;

  margin-bottom: 56px;
}

.specifications__category-title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  @include vp-599 {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }
}

.specifications__list {
  margin: 0;
}

.specifications__item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.specifications__paragraph {
  margin: 8px 0;

  color: $color-comet;
}

.specifications__term {
  margin-bottom: 8px;

  font-weight: 400;
}

.specifications__desc {
  margin: 0;

  color: $color-comet;
}

.slider-tabs__btn {
  &.is-active {
    background-color: $color-moon-yellow;
    border-color: $color-moon-yellow;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        background-color: $color-dark-tangerine;
        border-color: $color-dark-tangerine;
      }
    }
  }
}

.specifications__action-btns {
  margin: 0 auto;
  &:not(:first-child) {
    margin-top: 48px;
  }

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  @include vp-767 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
