.downloads {
  &--inverted {
    margin: 0;
    color: $color-default-white;

    background-color: $color-paua;
  }
}

.downloads__wrapper {
  .downloads--inverted & {
    margin: 0;
    padding: 64px 0;

    border-bottom: 1px solid $color-deluge;
  }
}

.downloads__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 56px;
  }

  @include vp-767 {
    font-size: 40px;
    line-height: percentage(48 / 40);

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}

.downloads__categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px 30px;

  margin: 0;
  padding: 0;

  list-style: none;

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);

    max-width: 570px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 40px 30px;
  }
}

.downloads__category {
  @include vp-767 {
    display: flex;
  }
}

.downloads__icon {
  flex-shrink: 0;
  display: block;
  width: 48px;
  height: 48px;
  margin: 0;
  margin-bottom: 24px;

  .downloads--inverted & {
    color: $color-default-white;
  }

  @include vp-767 {
    margin: 0;
    margin-right: 48px;
  }

  @include vp-413 {
    margin-right: 32px;
  }
}

.downloads__category-title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.downloads__category-list {
  margin: 0;
  padding: 0;
  padding-right: 40px;

  list-style: none;

  @include vp-767 {
    padding: 0;
  }
}

.downloads__category-item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
