.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-card__imgs {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.product-card__product-link {
  flex-grow: 1;

  font-size: 26px;

  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.product-card__inner {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 36px;
  }
}

.product-card__product-link {
  font-size: 26px;

  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.product-card__title {
  margin: 0;

  text-decoration: underline;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.product-card__desc {
  font-size: 18px;

  margin: 0;

  color: $color-comet;

  &:not(:last-child) {
    margin-bottom: 22px;
  }
}

.product-card__order-btn {
  width: fit-content;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.product-card__specifications-link {
  width: fit-content;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
