.cover-hero {
  position: relative;

  margin: 0;

  color: $color-default-white;

  &--light {
    color: $color-paua;
  }
}

.cover-hero__cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-color: $color-paua;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba($color-default-black, .6) 41.15%, rgba($color-default-black, 0) 72.40%);
    content: '';
  }

  .cover-hero--light & {
    background: none;

    &:before {
      background: linear-gradient(180deg, rgba($color-default-white, .7) 48.44%, rgba($color-default-white, 0) 75.52%);
    }
  }
}

.cover-hero__cover-img {
  width: 100%;
  height: 100%;

  object-fit: cover;

  @include vp-1239 {
    object-position: 25% center;
  }

  @include vp-767 {
    object-position: unset;
  }
}

.cover-hero__wrapper {
  min-height: 680px;
  display: flex;

  @include vp-1023 {
    min-height: 520px;
  }

  @include vp-767 {
    min-height: 400px;
  }
}

.cover-hero__container {
  @include vp-1023 {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.cover-hero__content {
  width: 50%;
  min-width: 400px;
  padding-top: 146px;
  padding-bottom: 146px;

  @include vp-1239 {
    width: 60%;
  }

  @include vp-1023 {
    width: 100%;
    min-width: unset;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;
  }

  .cover-hero--calc & {
    width: 60%;

    @include vp-1023 {
      width: 100%;
    }
  }
}

.cover-hero__title {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.cover-hero__slogan {
  margin: 0;

  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 46px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.cover-hero__paragraph {
  margin: 16px 0;
  font-weight: 600;
  max-width: 523px;

  @include vp-1023 {
    margin-left: auto;
    margin-right: auto;
  }

  @include vp-767 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .cover-hero--calc & {
    max-width: 625px;
  }
}

.cover-hero__action-btns {
  &:not(:first-child) {
    margin-top: 55px;
  }

  &:not(:last-child) {
    margin-bottom: 55px;
  }

  @include vp-767 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  .cover-hero--calc & {
    &:not(:first-child) {
      margin-top: 73px;
    }

    @include vp-767 {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}