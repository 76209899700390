.tabs-link {
  overflow: visible;
}

.tabs-link__list {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  gap: 50px;

  @include vp-1023 {
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 24px;
  }
}

.tabs-link__link {
  display: block;
  position: relative;
  padding-bottom: 18px;

  color: $color-wild-blue-yonder;

  font-weight: 500;
  text-decoration: none;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    border-bottom: 1px solid $color-wild-blue-yonder;
  }

  &.is-active {
    color: $color-governor-bay;

    font-weight: 700;

    &::after {
      border-bottom-width: 3px;
      border-color: $color-governor-bay;
    }
  }
}

.tabs-link__content-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.tabs-link__tab {
  width: 100%;
}
