.result-list {
  margin: 0;

  font-size: 22px;
  font-weight: 600;
  line-height: percentage(26 / 22);

  @include rvp-768 {
    display: grid;
    grid-template-columns: 59.7% min-content;
    justify-content: space-between;
    gap: 23px 30px;
    width: 100%;
  }
}

.result-list__label {
  width: 100%;

  @include vp-767 {
    margin-bottom: 12px;
  }
}

.result-list__data {
  width: 100%;
  display: grid;
  grid-template-columns: max-content min-content;
  gap: 30px;
  margin: 0;

  @include rvp-768 {
    grid-template-columns: auto 70px;
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.result-list__value {
  text-align: right;

  color: $color-dark-tangerine;

  .is-negative {
    color: $color-orioles-orange;
  }

  > * {
    position: relative;
  }

  .tippy-box {
    box-shadow: 0 2px 24px rgba($color-default-black, .08), 0 0 2px rgba($color-default-black, .08);
    text-align: left;
    font-weight: normal;
    color: rgba($color-jaguar, .65);
  }

  .tippy-content {
    padding: 8px 12px;
  }
}

.result-list__unit {
}