.dealer-card {
  height: 100%;
  padding: 28px;

  border: 1px solid $color-quartz;
  border-radius: 8px;

  @include vp-767 {
    padding: 20px;
  }
}

.dealer-card__flags {
  display: flex;
  flex-wrap: wrap;

  margin: -4px;
  padding: 0;

  list-style: none;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.dealer-card__flag {
  padding: 4px;
}

.dealer-card__flag-img {
  width: auto;
  max-width: 50px;
  height: 28px;

  object-fit: contain;
}

.dealer-card__name {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.dealer-card__address {
  font-style: inherit;
  color: $color-comet;
}

.dealer-card__post-address {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.dealer-card__contacts {
  margin: 0;
  padding: 0;

  list-style: none;
}

.dealer-card__contact {
  &--website:not(:first-child) {
    margin-top: 12px;
  }
}

.dealer-card__link {
  &--phone {
    text-decoration: none;
    color: inherit;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
