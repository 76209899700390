.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  color: $color-echo-blue;

  background-color: $color-default-white;
  border: 2px solid $color-ghost-white;
  border-radius: 50%;

  cursor: pointer;

  transition: color $trans-default;

  &:disabled {
    display: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-governor-bay;
    }
  }

  &--prev {
    left: -24px;

    .modal & {
      left: -24px;
    }
  }

  &--next {
    right: -24px;

    .modal & {
      right: -24px;
    }
  }

  @include vp-1239 {
    &--prev {
      left: 11px;
    }

    &--next {
      right: 11px;
    }
  }

  @include vp-767 {
    display: none;
  }
}

.nav-btn__icon {
  width: 32px;
  height: 32px;

  .nav-btn--prev & {
    transform: rotate(180deg);
  }
}
