g:hover .round {
	transform: scale(1.05) translate(-3%, -1.5%);
	-webkit-transition: transform 0.3s ease-out 0s;
	-moz-transition: transform 0.3s ease-out 0s;
	-o-transition: transform 0.3s ease-out 0s;
	transition: transform 0.3s ease-out 0s;
}

.round {
	-webkit-transition: transform 0.3s ease-out 0s;
	-moz-transition: transform 0.3s ease-out 0s;
	-o-transition: transform 0.3s ease-out 0s;
	transition: transform 0.3s ease-out 0s;
}

g:hover .shadow {
	opacity: 0.65 !important;
}

.shadow {
	-webkit-transition: transform 0.3s ease-out 0s, opacity 0.3s ease-out 0s;
	-moz-transition: transform 0.3s ease-out 0s, opacity 0.3s ease-out 0s;
	-o-transition: transform 0.3s ease-out 0s, opacity 0.3s ease-out 0s;
	transition: transform 0.3s ease-out 0s, opacity 0.3s ease-out 0s;
}

.st0:hover .round {
	transform: scale(1.05) translate(-3%, -1.5%);
}

.st1:hover .round {
	transform: scale(1.05) translate(-3%, -1.5%);
}

